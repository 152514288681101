import Box from '@mui/material/Box';
import React from "react";

const CenteredBox = (props: {children: React.ReactNode}) => {
    return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        width="100%"
    >
        {props?.children}
    </Box>
};

const marginCss = {
    marginTop: "10px",
    marginBottom: "10px",
}

const HorizontalCenteredBox = (props: {children: React.ReactNode}) => {
    return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        style={marginCss}
    >
        {props?.children}
    </Box>
};

export default CenteredBox;
export {HorizontalCenteredBox}