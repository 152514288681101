import React, {Suspense} from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {endpoint} from "./consts";
import LeftMenu from "./LeftMenu";
import AuthenticationContext from "./AuthenticationContext"
import IconButton from "@mui/material/IconButton";
import {Menu} from "@mui/icons-material";
import {v4 as uuidv4} from 'uuid';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import config from "./configs/used/plugins.config"

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {allInternalRoutes, filterRoutes, groupEndpoints} from "./routes/routes";
import CenteredLoader from "./tools/CenteredLoader";
import Collapse from "@mui/material/Collapse";
import {addTrailingSlash} from "./utils";


export default class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            endpoints: groupEndpoints(config.devEndpoints ?? []),
            alerts: [],
            collapsed: false
        }
    }

    async initLoad() {
        try {
            const response = await this.context.authenticatedFetch(endpoint + '/api/core/routes/v1')
            const json = await response.json()
            this.setEndpoints(json)
        } catch (e) {
            this.addAlert("Zobrazují se pouze aplikace, ke kterým není nutné žádné oprávnění. Popis chyby: " + e.toString(), "error", "Načtení cest a oprávnění se nezdařilo.")
        }
    }

    setEndpoints(endpoints) {
        endpoints = [...(config.devEndpoints) ?? [], ...endpoints]
        this.setState({endpoints: groupEndpoints(endpoints)})
    }

    // See https://projects.wojtekmaj.pl/react-lifecycle-methods-diagram/

    componentDidMount() {
        this.initLoad()
    }

    render() {
        const routes = this.state.endpoints === null ? {} : filterRoutes(allInternalRoutes, this.state.endpoints);
        const loggedIn = this.context.isUserLoggedIn();
        const allRoutes = Object.entries(routes).flatMap(([categoryName, category]) => (category.children)).map((routeConfig) =>

            <Route path={addTrailingSlash(routeConfig.path) + "*"}
                   key={routeConfig.path}
                   element={

                    React.createElement(routeConfig.element, {endpoints: this.state.endpoints})

            }/>
        );


        return (<Router>
            <Box sx={{display: 'flex', height: '100%'}}>
                <CssBaseline/>
                <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => this.setState({collapsed: !this.state.collapsed})}
                        >
                            <Menu />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            { config.name ?? "Glued" }
                        </Typography>
                        <title>{ config.name ?? "Glued" }</title>

                        <Button color="inherit" onClick={loggedIn ? this.handleLogout : this.handleLogin}>{loggedIn ? "Log out" : "Log in"}</Button>
                    </Toolbar>
                </AppBar>

                { /*TODO: Tahle animace vypadá příšerně*/ }
                <Collapse orientation="horizontal" mountOnEnter unmountOnExit in={ !this.state.collapsed }>
                    <LeftMenu routes={routes} onRouteSelected={() => this.setState({collapsed: true})}/>
                </Collapse>



                <Box component="main" sx={{flex: 1, p: 3, height: '100%', minWidth: 0}}>
                    <Stack spacing={2} justifyContent="space-between" sx={{height: '100%'}}>
                        <Toolbar/>
                        {
                            this.state.alerts.length > 0 ? <Stack sx={{ width: '100%' }} spacing={2}>
                                {this.state.alerts.map(alert => {
                                    return <Alert severity={alert.severity} onClose={() => {this.removeAlert(alert.uuid)}} key={alert.uuid} variant="filled">
                                        {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                                        {alert.text}
                                    </Alert>
                                })}
                            </Stack> : null}

                        <Box sx={{flex: 1, minHeight : 0}} >
                            <Suspense fallback={<CenteredLoader/>}>
                                <Routes>
                                    {allRoutes}
                                </Routes>
                            </Suspense>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Router>);
    }

    handleLogout = () => this.context.logout();
    handleLogin = () => this.context.login();


    addAlert = (text, severity, title) => {
        const newAlert = {
            uuid: uuidv4(),
            text: text,
            severity: severity ?? 'info',
            title: title
        };

        this.setState( prevState => ({
            alerts: [newAlert, ...prevState.alerts]
        }))
    };

    removeAlert = (uuid) => {
        this.setState(prevState => ({
            alerts: prevState.alerts.filter(alert => alert.uuid !== uuid)
        }))
    }

}

Main.contextType = AuthenticationContext;